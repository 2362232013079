<template>
  <div>
    <div class="page-name">
      <span>
        <i class="fas fa-map-marker-alt"></i>
        {{i18n('Custom.DepartmentNatureAttributeMaintenance')}}
      </span>
    </div>

    <div class="filter-block">
      <div class="filter-field">
        <span class="title">
        {{i18n('Custom.DepartmentCodeName')}}</span>
        <input
          type="text"
          v-model="searchInfo.departmentKeyword"
          placeholder=""
        />
      </div>
      <div class="filter-field">
        <span class="title">{{i18n('Custom.BusinessNature')}}</span>
        <select v-model="searchInfo.natureGuid" required>
          <option :value="null" selected>
            {{i18n('Basic.All')}}
          </option>
          <option
            v-for="nature in natureOptions"
            :key="nature.Value"
            :value="nature.Value"
          >
            {{ nature.Text }}
          </option>
        </select>
      </div>

      <div class="filter-btn-wrap">
        <a class="btn btn-yellow" @click.prevent="getNatureList">{{i18n('Basic.Search')}}</a>
      </div>
    </div>

    <div class="d-flex justify-content-end mb-2">
      <button class="btn btn-sm btn-blue mr-2" @click.prevent="saveNature()">
        {{i18n('Basic.Save')}}
      </button>
    </div>

    <vue-bootstrap4-table
      :rows="natureList"
      :columns="columns"
      :config="config"
      :classes="classes"
    >
      <template slot="NatureGuid" slot-scope="props">
        <select v-model="props.row.NatureGuid" @change="pushTempList(props.row)">
          <option
            v-for="nature in natureOptions"
            :key="nature.Value"
            :value="nature.Value"
          >
            {{ nature.Text }}
          </option>
        </select>
      </template>
    </vue-bootstrap4-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      screen: null, // loading遮罩
      natureList: [],
      natureOptions: [],
      tempList: [],
      searchInfo: {
        natureGuid: null,
      },

      config: {
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        per_page: 10,
        per_page_options: [10, 25, 50],
        global_search: {
          visibility: false,
        },
        // checkbox_rows: true,
      },
      classes: {
        table: 'table-light table-striped',
        // via: 'table-danger'
      },
    };
  },
  computed:{
    columns(){
      const col= [
        {
          label: this.i18n('Custom.DepartmentCode'),
          name: 'DepartmentCode',
          sort: true,
        },
        {
          label: this.i18n('Custom.DepartmentName'),
          name: 'DepartmentName',
          sort: true,
        },
        {
          label: this.i18n('Custom.BusinessNature'),
          name: 'NatureGuid',
          slot_name: 'NatureGuid',
        },
      ]
      return col;
    }
  },
  methods: {
    // 多國語系
    i18n(keyStr) {
      return this.$t(keyStr);
    },
    getNatureOptions() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Org/Dept/Nature/Option`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: '錯誤',
              message: response.data.ErrorMessage,
              type: 'error',
              duration: 0,
            });
          } else {
            this.natureOptions = response.data.Data;
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: '錯誤',
            message: error.response.data.ErrorMessage,
            type: 'error',
            duration: 0,
          });
          this.screen.close();
        });
    },
    getNatureList() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Org/Dept/Nature`;
      const params = { ...this.searchInfo };
      this.$http
        .get(url, { params })
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: '錯誤',
              message: response.data.ErrorMessage,
              type: 'error',
              duration: 0,
            });
          } else {
            this.natureList = response.data.Data;
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: '錯誤',
            message: error.response.data.ErrorMessage,
            type: 'error',
            duration: 0,
          });
          this.screen.close();
        });
    },
    pushTempList(row) {
      const vm = this;
      const p = vm.tempList.find((item) => item.DepartmentGuid === row.DepartmentGuid);
      if (p) {
        p.NatureGuid = row.NatureGuid;
      } else {
        vm.tempList.push(row);
      }
    },

    saveNature() {
      this.screen = this.openLoading();
      const vm = this;
      const api = `${window.BaseUrl.api}/Org/Dept/Nature`;
      vm.$http.put(api, vm.tempList).then((response) => {
        if (response.data.ErrorMessage) {
          this.$notify({
            title: '錯誤',
            message: response.data.ErrorMessage,
            type: 'error',
            duration: 0,
          });
        } else {
          vm.tempList = [];
          this.$store.dispatch('alertMessageModule/updateMessage', {
            message: '儲存成功',
            status: 'success',
          });
          this.getNatureList();
        }
        this.screen.close();
      })
        .catch((error) => {
          this.$notify({
            title: '錯誤',
            message: error.response.data.ErrorMessage,
            type: 'error',
            duration: 0,
          });
          this.screen.close();
        });
    },

  },
  created() {
    this.getNatureOptions();
  },
};
</script>
